<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.maxStayForm"
    />
    <h3 class="pb-2" v-if="!addingRule">
      Maximum Stay
      <hr />
    </h3>
    <button
      class="btn btn-primary mb-3"
      v-if="!addingRule"
      @click="addingRule = true"
    >
      Add New Rule
      <i class="fa fa-plus-circle" />
    </button>
    <div v-if="addingRule" class="card">
      <div class="card-header">
        <div class="card-title mb-0">Maximum Stay</div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="maxStayForm">
          <form @submit.prevent="onSubmit">
            <AdminCommonBusinessRuleFields
              :rule="maxStayRule"
              :referenceId="referenceId"
            />
            <hr />
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="required|between:1,999"
                  name="Maximum Stay"
                  id="ruleLength"
                  placeholder="Enter maximum stay"
                  v-model="maxStayRule.maximumStayDays"
                />
                <small class="form-text text-muted mb-2"
                  >Maximum stay allowed in a single reservation.</small
                >
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="required|between:1,999"
                  name="Maximum Stay Per Period"
                  id="ruleLengthPerPeriod"
                  placeholder="Enter maximum stay per period"
                  v-model="maxStayRule.maximumStayPeriodDays"
                />
                <small class="form-text text-muted mb-2">
                  Total number of reservation days allowed in a given period of
                  time.
                </small>
              </div>
            </div>
            <button type="submit" class="btn btn-lg btn-primary mt-3">
              Submit
            </button>
            <button
              class="btn btn-cancel btn-lg mt-3 ml-1"
              @click="addingRule = false"
              type="button"
            >
              Cancel
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <AdminBusinessRuleTable
      :tableData="tableData"
      @viewRule="viewRule"
      @editRule="editRule"
      :uniqueFields="uniqueFields"
    />
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AdminCommonBusinessRuleFields from "@/components/admin/AdminCommonBusinessRuleFields";
import AdminBusinessRuleTable from "@/components/admin/AdminBusinessRuleTable";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminBusinessRuleService from "@/services/admin/AdminBusinessRuleService.js";
import TextInput from "@/validation/TextInput.vue";

export default {
  name: "AdminMaximumStay",
  title: "Admin - Maximum Stay",
  components: {
    ValidationObserver,
    AdminCommonBusinessRuleFields,
    AdminBusinessRuleTable,
    FormErrorAlert,
    TextInput
  },
  data() {
    return {
      maxStayRule: {
        name: "Maximum Stay",
        isActive: true,
        productId: "",
        productTypeId: "",
        areaId: "",
        loopId: "",
        seasonTypeId: "",
        salesChannelId: "",
        paymentTypeId: ""
      },
      tableData: [],
      baseOptions: [{ id: "", name: "All" }],
      errors: [],
      uniqueFields: [
        { key: "maximumStayDays", sortable: true },
        { key: "maximumStayPeriodDays", sortable: true }
      ],
      addingRule: false
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    referenceId() {
      return `C${(this.tableData.length + 1 + "").padStart(3, "0")}`;
    }
  },
  methods: {
    async initialize() {
      this.loadData();
    },
    async loadData() {
      const businessRuleService = new AdminBusinessRuleService(this.tenantId);
      businessRuleService.getAllMaximumStay().then(response => {
        this.tableData = response.data.map((x, index) => {
          return { ...x, referenceId: `C${(index + 1 + "").padStart(3, "0")}` };
        });
      });
    },
    async onSubmit() {
      this.$refs.maxStayForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.maxStayForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.maxStayForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          //Map to request object
          var request = {
            isActive: this.maxStayRule.isActive,
            productId: this.maxStayRule.productId,
            productTypeId: this.maxStayRule.productTypeId,
            areaId: this.maxStayRule.areaId,
            loopId: this.maxStayRule.loopId,
            salesChannelId: this.maxStayRule.salesChannelId,
            seasonTypeId: this.maxStayRule.seasonTypeId,
            paymentTypeId: this.maxStayRule.seasonTypeId,
            comments: this.maxStayRule.comments,
            startDate: this.maxStayRule.startDate,
            endDate: this.maxStayRule.endDate,
            length: this.maxStayRule.length,
            unitId: this.maxStayRule.unitId,
            maximumStayDays: this.maxStayRule.maximumStayDays,
            maximumStayPeriodDays: this.maxStayRule.maximumStayPeriodDays,
            validationMessage: this.maxStayRule.validationMessage
          };
          if (this.maxStayRule.productClasses) {
            request.productClassIds = this.maxStayRule.productClasses.map(
              n => n.id
            );
          }
          const businessRuleService = new AdminBusinessRuleService(
            this.tenantId
          );
          var response = await businessRuleService.addMaximumStay(request);
          if (response.statusCode == "Success") {
            //Reload data for grid
            this.loadData();
            this.maxStayRule = {
              name: "Maximum Stay",
              isActive: true,
              productId: "",
              productTypeId: "",
              areaId: "",
              loopId: "",
              seasonTypeId: "",
              salesChannelId: "",
              paymentTypeId: ""
            };
            this.addingRule = false;
            window.scrollTo(0, 0);
          }
        }
      });
    },
    viewRule(item) {
      this.$router
        .push(
          `/admin/brm/maximum-stay/${item.id}?refId=${item.referenceId}&v=true`
        )
        .catch(() => {});
    },
    editRule(item) {
      this.$router
        .push(
          `/admin/brm/maximum-stay/${item.id}?refId=${item.referenceId}&v=false`
        )
        .catch(() => {});
    }
  },
  created() {
    this.initialize();
  }
};
</script>
